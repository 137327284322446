<template>
  <div>
    <h3 v-if="!valid"> {{ error_message }}</h3>
    <div v-show="valid" class="live-video" >
    </div>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import router from '@/router';
import axios from 'axios';
import { Camera } from "@omnicloud/cameras-sdk";

import { ref } from "@vue/composition-api";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
  },
  setup() {
    const alarm = router.currentRoute.params.alarm;
    const token = router.currentRoute.params.token;

    const valid = ref(false);
    const cameraSDK = ref(null);
    const error_message = ref('Cargando ...');

    const connectEvents = () => {
      localStorage.setItem('accessToken', token);
      if (!window.Echo.connector.socket.connected)
      {
        window.Echo.connect();
        setTimeout(connectEvents, 200);
      } else {
        window.Echo.private(`alarm-${alarm}`)
          .listen(".updated", (data) => {
            if (data.alarm_status == 'canceled') {
              cameraSDK.value.destroy();
              valid.value = false;
              error_message.value = "La alarma ha sido cancela";
            }

            if (data.alarm_status == 'attended') {
              cameraSDK.value.destroy();
              valid.value = false;
              error_message.value = "La alarma ha sido atendida";
            }
          })
      }
    }

    axios.get(`${process.env.VUE_APP_BASE_URL}/v1/alarm/${alarm}`,
      {
        params: {
          waiting: 1,
          attending: 1
        },
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
      .then(result => {
        valid.value = true;
        connectEvents();
        result.data.data.unit?.cameras?.forEach(camera => {
          if (camera.name.indexOf('C1') > 0)
          {
            cameraSDK.value = new Camera(camera.camera_type, {
              cameraId: camera.camera_id,
              token,
              htmlElement: `.live-video`,
              style: "float",
              apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
              debugMode: "info|error",
              mode: "default",
              capabilities: {
                closeBtn: false,
                dateRangeBtn: false,
                date: false,
                recordingBtn: false,
                timelineBtn: false,
              },
            });
            cameraSDK.value.buildPlayer();
          }
        })
      })
      .catch(err => {
        error_message.value = err.response.data.message
      });

    return {
      valid,
      error_message,
    };
  },
};
</script>

<style lang="scss">
.live-video {
  width: 100vw;
  height: 100vh;
}
</style>
